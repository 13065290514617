<template>
  <b-card title="Slider">
    <b-tabs>
     <b-tab active @click="$router.push('/add-slider')" >
        <template #title>
          <feather-icon icon="PlusIcon" />
          <span>Add Slider</span>
        </template>

  <AddSlider></AddSlider>
      </b-tab>
      <b-tab  @click="$router.push('/all-sliders')">
        <template #title>
          <feather-icon icon="HomeIcon" />
          <span> All Sliders</span>
        </template>
        <router-view></router-view>
    <!-- <ViewSliders></ViewSliders> -->
      </b-tab>
     
       
     
    </b-tabs>

  </b-card>
</template>

<script>

import { BTabs, BTab, BCardText,BCard } from 'bootstrap-vue'

import ViewSliders from './ViewSliders.vue';
import AddSlider from './AddSlider.vue';


export default {
  components: {
    BCard,
    BCardText,
    BTabs,
    BTab,
  
    ViewSliders,
    AddSlider
},
  data() {
    return {
   
    }
  },
}
</script>
